@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;600&display=swap);
body {
  font-family: 'Montserrat', sans-serif;
  background: #010101;
}

/* Variables */
:root {
    --linear-button-bg: linear-gradient(97.32deg, #7981EB 9.51%, #F16085 91.96%), #FFAD01;
    --linear-button-outline: radial-gradient(50% 50% at 50% 50%, #3A2441 0%, rgba(58, 36, 65, 0) 100%);
    --color-orange: #FFAD01;
    --title-fsz: 65px;
    --title-fszm: 45px;
}


.btn:focus {
    outline: none;
    box-shadow: none;
  }

.pagination .page-link {
    color: #212121;
    text-decoration: none;
    border: 0;
}


.gradientButton {
    width: 219px;
    /* background: linear-gradient(97.32deg, #7981EB 9.51%, #F16085 91.96%), #FFAD01; */
    background: linear-gradient(97.32deg, #7981EB 9.51%, #F16085 91.96%), #FFAD01;
    background: var(--linear-button-bg);
    border-radius: 8px;
    color: #ffffff;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    padding: 16px 32px;
    text-decoration: none;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}
.gradientButton:hover {
    color: #dfdfdf;
}
.olButton {
    width: 219px;
    background: linear-gradient(97.32deg, #7981EB 9.51%, #F16085 91.96%), #FFAD01;
    background: var(--linear-button-bg);  
    color: #ffffff;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    padding: 16px 32px;
    text-decoration: none;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    border-radius: 8px;
    -webkit-flex: none;
            flex: none;
-webkit-order: 0;
        order: 0;
-webkit-flex-grow: 0;
        flex-grow: 0;
margin: 0px 32px;
}
.orangeButton {
    background: #FFAD01;
    border-radius: 8px;
    color: #ffffff;
    text-decoration: none;
    padding: 16px 32px;

}
.orangeButton:hover {
    color: #dfdfdf;
}


.card-title {
    color: #ffffff;
    font-weight: 600;
}
.card-body p {
    color: #d0d0d0;
    font-weight: 300;
    font-size: 16px;
}

.starTop {
    background: url(/static/media/Star1.a41f480f.png), radial-gradient(129.61% 88.13% at 49.27% 2.67%, #3D2644 0%, #000000 100%);
    background-repeat: no-repeat;
    background-position: top;
}

/* Header / NAV */
.navbar-brand {
    width: 140px;
}

/* Hero Section */
.heroSection {
    height: 800px;
    /* background: radial-gradient(129.61% 88.13% at 49.27% 2.67%, #3D2644 0%, #000000 100%); */
    background: url(/static/media/Star1.a41f480f.png), radial-gradient(129.61% 88.13% at 49.27% 2.67%, #3D2644 0%, #000000 100%);
    background-repeat: no-repeat;
    background-position: top;
}
.heroSection h1 {
    font-size: 65px;
    font-size: var(--title-fsz);
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
}
.cloudsHero {
    background: url(/static/media/clouds.d02d6c72.svg) no-repeat;
    background-size: auto;
    background-position: center;
}

.textOrange { color: #FFAD01; color: var(--color-orange);}
.contentWrapper .row {
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.heroDogCenter {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.heroDogCenter #my-svg {
    max-width: 360px;
}
.heroLeftBlock, .heroRightBlock {
    width: 300px;
    height: 420px;
    /* left: calc(50% - 300px/2 - 442px);
    top: 297px; */
    padding: 16px;
    background: linear-gradient(163.37deg, rgba(24, 25, 28, 0.25) -1.49%, rgba(24, 25, 28, 0) 100.05%);
    -webkit-backdrop-filter: blur(16px);
            backdrop-filter: blur(16px);
    /* Note: backdrop-filter has minimal browser support */
    border: 1px solid #ffffff;
    border-radius: 32px;
    margin: 42px auto;
}

.heroLeftBlock .card-img-bg, .heroRightBlock .card-img-bg {
    background: linear-gradient(122.4deg, #7981EB -1.47%, #F16085 102.19%);
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);
    border-radius: 32px;
    width: 268px;
    height: 268px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.card-img-top {width: 141.2px;}

.heroButtons {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 4rem;
}

@media screen and (max-width: 768px) {
    .heroSection {
        height: 580px;
    }
    .cloudsHero {
        background-size: cover;
        -webkit-align-items: baseline;
                align-items: baseline;
    }
    .heroLeftBlock, .heroRightBlock {
        width: 219px;
        height: 321px;
    }
    .heroLeftBlock .card-img-bg, .heroRightBlock .card-img-bg {
        width: 187px;
        width: 187px;
    }
    .heroDogCenter img {
        width: 202px;
    }
    .card-img-top {
        width: 90.8px;
    }
    .heroButtons {
        margin-top: 1.5rem;
    }
}

/* Features Section */
.features {
    margin-top: 115px;
    background: radial-gradient(50% 50% at 50% 50%, #3A2441 0%, rgba(58, 36, 65, 0) 100%);
    /* background: url('../img/star6.png') no-repeat; */
}
.blockTitle{
    font-style: normal;
    font-weight: bold;
    font-size: 100px;
    line-height: 96px;    
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 5rem;
}

    @supports (-webkit-text-stroke: 1px rgba(255, 255, 255, 0.15)) { /* если есть поддержка свойства: невидимый текст посетителям не понравится */
        .blockTitleOverlay {
            color: transparent; /* прозрачный цвет букв */
            -webkit-text-stroke: 1px rgba(255, 255, 255, 0.15);
            font-size: 7vw;
            font-weight: bold;
            line-height: 79px;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: -5rem;
        }
        
    }


.featRow {
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    padding: 10rem 0;
}
.featRow .card {
    width: 227px;
    height: 268px;
    background: transparent;
    border: none;
}
.featRow .card img {
    width: auto;
    height: 200px;
    margin: -80px auto;
    z-index: 1;
}
.featRow .card-body {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 120%;
    text-align: center;
    color: #C1C1C1;
    padding-top: 90px;
    border: 1px solid rgba(250, 250, 250, 0.25);
    border-radius: 34px;
}
.ctitle {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 120%;
    color: #ffffff;
}


/* How it works section */
.howIt {
    margin-bottom: 4rem;
}
.glass {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    max-width: 1184px;
    height: 681px;
    background: linear-gradient(97.32deg, rgba(121, 129, 235, 0.15) 9.51%, rgba(241, 96, 133, 0.15) 91.96%);
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);
    -webkit-backdrop-filter: blur(16px);
            backdrop-filter: blur(16px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 32px;
    margin: 0 auto;
    margin-top: 70px;
    padding: 30px;
}
.glass object {
    width: 1057px;
    margin-top: -27px;
}
@media screen and (max-width: 768px) {
    .glass {
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        height: 558px;
        overflow: scroll;
    }
    .glass img {
        max-width: 1070px;
        width: 1070px!important;
        margin-top: -27px;
    }

}
/* Today market section */
.bodyWrapper.market .footer{
    position: relative;
}
.todayMarket .row {
    -webkit-justify-content: center;
            justify-content: center;
}
.todayMarket .card {
    width: 274px;
    height: 348px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid #C5C5C5;
    box-sizing: border-box;
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
    border-radius: 16px;
    margin: 0 auto;
    overflow: hidden;
}
.todayMarket .card-img-bg {
    background: linear-gradient(55.34deg, #6058D5 3.28%, #816BA4 92.44%);
    height: 255px;
}
.todayMarket .col-md {
    margin: 15px;
    max-width: 274px;
}
.todayMarket .card-body {
    padding: 5px 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}
.todayMarket .card-body p {
    margin: -10px 0 5px 0;
}
.priceBottom {
    display: -webkit-flex;
    display: flex;

}
.regPrice {
    color: #FFAD01;
    font-size: 18px;
    font-weight: 600;
}
.todayMarket .gradientButton {
    margin: 2.6rem auto;
}
.todayMarket .gradientButton:hover {
    color: #f1f1f1;
}
.dodRound {
    width: 36px;
    height: 36px;
    background: linear-gradient(
122.4deg
, #7981EB -1.47%, #F16085 102.19%);
    box-shadow: 0px 0px 32px rgb(0 0 0 / 25%);
    border-radius: 50%;
    color: #ffffff;
    text-align: center;
    line-height: 100px;
    vertical-align: middle;
    padding: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-decoration: none;
}
.dodRound i {
    font-size: 19px;
}
.dodRound:hover {
    color: #f1f1f1;
}
.sellPrice {
    position: absolute;
    left: 95px;
    font-size: 14px;
    color: #FFAD01;
    text-decoration: line-through;
}
.ready2nft {
    position: absolute;
    width: 129.54px;
    height: 36.82px;
    left: 179.72px;
    top: 14.18px;
    background: linear-gradient(81.91deg, #7981EB 20.25%, #F16085 87.16%);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.ready2nft img {
    width: 25px;
    height: 30px;
    left: 232px;
    top: 11px;
    /* background: #FFFFFF; */
    /* box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25); */
    -webkit-transform:rotate(-45deg);
  transform: rotate(-45deg);
}
@media screen and (max-width: 768px) {
    .todaySecondRow {
        display: none;
    }
}

/* Two learn blocks */
.learnBlock {
    width: 100%;
    height: 459px;
    background: linear-gradient(97.32deg, #7981EB 9.51%, #F16085 91.96%);
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);
    border-radius: 32px;
    display: -webkit-inline-flex;
    display: inline-flex;
    margin: 3rem auto;
}
.learnBlock img {
    max-width: 100%;
}
.lbLeft {
    width: 60%;
    padding: 57px 50px;
}
.lbRight {
    width: 39%;
    padding: 22px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.lbTitle {
    font-weight: bold;
    font-size: 80px;
    line-height: 98px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 57px;
}
.lbContent {
    font-weight: 300;
    font-size: 18px;
    line-height: 1.8;
    color: #D9D9D9;
    margin-bottom: 57px;
}
@media screen and (max-width:768px) {
    .learnBlock {
        -webkit-flex-direction:column-reverse;
                flex-direction:column-reverse;
        height: auto;
    }
    .lbLeft, .lbRight {
        width: 100%;
    }
    .lbTitle {
        font-size: 40px;
    }
    .lbTwo {
        -webkit-flex-direction: column;
                flex-direction: column;
    }
}
/*Profile*/
.filterShow{
width: 750px;
margin-left: 20px;
}
.rounded-control {
    height: 50px;
    padding: 0 1.4rem;
    border: 1px solid #767676;
    box-sizing: border-box;
    border-radius: 52px;
    box-sizing: border-box;
    border-image-source: linear-gradient(rgba(78, 159, 226, 1), rgba(152, 78, 226, 1));
    border-width: 1pt;
    border-image-slice: 1;
    cursor: pointer;
    margin-left: 10px;
}
select.sorting-select {
    /* -webkit-appearance: none; */
  }
  select.sorting-select::after {
      content: "â–¼";
      padding: 0 8px;
      font-size: 12px;
      position: absolute;
      right: 8px;
      top: 4px;
      z-index: 1;
      text-align: center;
      width: 10%;
      height: 100%;
      pointer-events: none;
      box-sizing: border-box;
  }
  input.search-dog.rounded-control {
      width: 383px;
  }
  .select-filter-wrap {
      margin-left: 32px;
  }
  .sorting-select {
      padding-right: 2rem;
  }
/* My DOGS page */
.bodyWrapper.my-dogs-page {
    min-height: calc(100vh - 56px);
}
.bodyWrapper.my-dogs-page .footer {
    position: relative;
}
.topContainer {
    max-width: 82vw;
}
.topConFirst {  padding: 2rem 0;}
.mydogsLeft {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
}
.mydogsLeft .logo {
    margin-right: 1rem;
}
.profName {
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;

    color: #0D090E;
}
.profStatus {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: #C5C5C5;
}
.disable {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: #C5C5C5;
    text-decoration: none;
    margin-left: 1rem;
}
.topConDrops {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.transactionClass{
    /*background-color: #fff;
    overflow-y: scroll;*/
    height:  calc( 100vh - 300px );
}
.myDogs {
    background-color: #fff;
    overflow-y: scroll;
    height:  calc( 100vh - 100px );
}
.myDogs a {
    text-decoration: none;
}
.myDogs .card-title {
    color: #303030;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.pagi {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 32px;
}
@media screen and (max-width: 768px) {
    .topContainer {
        max-width: 100%;
    }
}
.mt-5{
    margin-top: 0rem!important;
}

/* Transaction Page */
.bodyWrapper {
    background-color: #fff;
    
}
.tRowTop {
    padding: 58px 0;
}
.tRowTop h2{
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    text-transform: uppercase;
    color: #050406;
}
.bodyWrapper.transaction-page {
    height: calc(100vh - 56px);
}
.bodyWrapper.transaction-page .footer{
    position: relative;
}

/* About us page */
.aboutFirst .address {
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #303030;
    white-space: pre;
}
.aboutFirst h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 55px;
    text-align: center;
    text-transform: uppercase;
    color: #050406;
    padding: 55px 0;
}
.aboutFirst h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 120%;
    text-align: center;
    color: #303030;
    text-align: center;
}
.aboutMainContent h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 120%;
    text-align: center;
    color: #303030;
}
.aboutMainContent {
    font-weight: 200;
}
.aboutMainContent ul {
    padding-left: 1rem;
    list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAF7SURBVHgBlVI7UsNADH1aAjT8Klp8A8IJcE4QmIGCiuQEhJMAJ0hSpSAZk4Ia5wbmBPgGYYahgYmFVv6tbWBAM2vLWr0nWU8Ex3iIPWyhDWO6YPZB8CS8J+8QTBGSZE7n4jtGCpxI4gbdiOsLMJb3Qk6INUSatRJSewccg8jTuw++pgvELU1Yp0CACwEc0SliNM3GHrRYwB4SnGCThvLVSVuf0ZIfcYA/GgfwLKYMzIjxT8sxpgjYAboJE63yomeiw3Q7KHJzghj72K2UaKEnT09P6pf2qfG4JGD5eEenRjDKkuLML80IAbFLIAoYlaqwVA0K5TJsKEOyJyvMS4KWSER0WZ+DELdlgSrE2YWvmJxAKkTyGxF2MKhW4js9LjQwV7b9Rld8D1/3oTbxSk6uTFDmFDLqjjOP7VY2fgWZzHZjwWO3OjUSp2Ykz0O8cYf6eC3A2/Qk3jOdVSWlb1ud4laG2kXC/bRP2XtRqg7+1XiGXraJS5nP4Ke8L1K1n51ucUxRAAAAAElFTkSuQmCC); 
}
.aboutMainContent ul li {
    font-size: 17px;
    line-height: 2.5;
}

/* My Dog Page */
.bodyWrapper.dog-page .footer{
    position: relative;
}
.dpRow {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.dpTitles {
    font-size: 28px;
    color: #000000;
    font-weight: normal;
}
.dogPageContainer {
    padding-top: 3rem;
}
.dogImgContainer {
    width: 400px;
    height: 400px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 32px;
    background: linear-gradient(
55.34deg
, #FFD34E 3.28%, #FFE38F 92.44%);
    margin: 0 auto;
    overflow: hidden;
}
.dogImgBage {
    position: absolute;
    width: 191.54px;
    height: 53.82px;
    right: -47px;
    top: 17.18px;
    background: linear-gradient(203.15deg, #7981EB 26.37%, #F16085 74.48%);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.dogImg {
    width: 450px;
    height: 450px;
    margin: 0 auto;
}
.dogName {
    font-weight: bold;
    font-size: 36px;
    line-height: 120%;
    color: #000000;
}
.owner {
    font-size: 17px;
    color: #636363;
    font-weight: 200;
}
.owmerName {
    color: #FFAD01;
}
.ownerTextBottom {
    margin-top: 32px;
    font-weight: 200;
    color: #636363;

}
.dogAbout {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: start;
            justify-content: start;
    margin-top: 32px;
}
.par {
    margin-right: 2rem;
}
.par .parTitle {
    font-size: 18px;
    color: #000000;
}
.par .parText {
    font-size: 18px;
    color: #636363;
    font-weight: 200;
}
.dogStats {
    margin-top: 32px;
    font-size: 18px;
    color: #636363;
    font-weight: 200;
}

.racingStats {
    margin-top: 48px;
}
.rsData {
    display: -webkit-flex;
    display: flex;
}
.rsCol {
    width: 49%;
}
.dnaAnalys {
    margin-top: 48px;
}
.dnaSeq {
    font-weight: 200;
    color: #636363;
}

.dogParents, .dogChildren {
    margin-top: 48px;
}
.parentsBlock, .childrenBlock {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    -webkit-justify-content: baseline;
            justify-content: baseline;
    padding: 32px 0;
}
.parentsBlock .card, .childrenBlock .card{
    height: 348px;
    overflow: hidden;
    border-radius: 16px;
}
.parentsBlock .card-body, .childrenBlock .card-body {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 15px;
}
.parentsBlock .card-title, .childrenBlock .card-title {
    color: #303030;
}
.sellBlock {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
    padding: 1.5rem 0;
}
.cp {
    font-size: 36px;
    font-weight: bold;
}
.sellBlock .gradientButton {
    width: 202px;
    height: 54px;
}
@media screen and (max-width: 1024px) {
    .parentsBlock, .childrenBlock {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 15px;
        max-width: 280px;
        margin: 0 auto;
    }
    .dogImgContainer {
        width: 100%;
    }
    .dpRow {
        -webkit-flex-direction: column;
                flex-direction: column;
    }
}

@media screen and (max-width: 768px) {
    .dogImgContainer {
        width: 100%;
        height: 100%;
    }
    .dpRow {
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .parentsBlock .card-img-bg, .childrenBlock .card-img-bg {
        display: -webkit-flex;
        display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    }
}
@media screen and (max-width: 360px) {
    .dogImgContainer {
        width: 100%;
        height: 100%;
    }
    .dpRow {
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .parentsBlock .card, .childrenBlock .card{
        width: 168px;
        overflow: hidden;
        border-radius: 16px;
    }
    .parentsBlock .ready2nft, .childrenBlock .ready2nft {
        left: 77.72px;
    }
}
/* Login Page */
.login-page {
    height: 800px;
    background: url(/static/media/Star1.a41f480f.png), radial-gradient(129.61% 88.13% at 49.27% 2.67%, #3D2644 0%, #000000 100%);
    background-repeat: no-repeat;
    background-position: top;
}
.login-wrapper {
    padding: 8.5rem;
    margin: 0 auto;
}
.login-page-title .blockTitle {
    font-size: 65px;
}
.login-page-title .blockTitleOverlay {
    font-size: 100px;
    margin-bottom: -8rem;
}
.login-page-title img {
    margin-left: -5rem;
}
.enter-email-and-pass {
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #D9D9D9;
    margin: 4rem 0 2rem;
}
.enter-email-and-pass a {
    color: #FFAD01;
}
.form-container {
    position: relative;
    max-width: 479px;
    margin: 0 auto;
}
.login-form-main input::-webkit-input-placeholder { color: #727272; }
.login-form-main input::-moz-placeholder { color: #727272; }
.login-form-main input[type="text"] {
    color: #ffffff;
}
.form-container .form-control {
    max-width: 448px;
    height: 54px;
    border: 1px solid #B6B6B6;
    box-sizing: border-box;
    border-radius: 8px;
    background: transparent;
    margin: 16px auto;
}
.login-forgot a {
    font-size: 14px;
    line-height: 17px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    color: #FFAD01;
    margin: 8px 0 3rem;
    text-decoration: none;
}
.login-button {
    width: auto;
    margin: 0 auto;
    max-width: 448px;
    height: 54px;
}
.login-or {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    text-align: center;
    color: #4F4F4F;
    margin: 2rem 0 3rem;
}
.metamask-conn {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.meta-conn {
    background: linear-gradient(89.19deg, #7981EB -85.56%, #F16085 168.9%);
  /* background: -webkit-linear-gradient(#eee, #333); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 8px;
}
.login-footer, .login-footer a {
    color: #ffffff!important;
}
.login-footer .cRight, .login-footer .footerMenu {
    padding: 0.5rem;
}
@media screen and (max-width: 768px) {
    .login-wrapper {
        padding: 3.5rem 1rem;
    }
    .form-container .form-control {
        width: 309px;
        margin: 16px auto;
    }
    .login-button {width: 309px;}
    .login-page-title .blockTitle {
        font-size: 35px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
    }
    .login-page-title .blockTitleOverlay {
        font-size: 55px;
        margin-bottom: -5rem;
    }
    .login-page-title img {
        margin-left: -1.5rem;
    width: 120px;
    height: 120px;
    margin-top: -1.3rem;
    }
    .enter-email-and-pass {
        font-size: 16px;
    }
    .login-footer a {
        color: #ffffff!important;
    }
    .login-footer .cRight, .login-footer .footerMenu {
        width: 100%;
        max-width: 100%;
        text-align: center;
        padding: 0.5rem;
    }
}

/* Footer Section */
.foo {
    color: #ffffff;
    max-height: 734px;
    background: linear-gradient(97.32deg, rgba(121, 129, 235, 0.3) 9.51%, rgba(241, 96, 133, 0.3) 91.96%);
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);
    -webkit-backdrop-filter: blur(16px);
            backdrop-filter: blur(16px);
    border-radius: 32px 32px 0px 0px;
    /* padding: 50px; */
}
.foo .footerMenu a {
    color: #ffffff;
}
.fooLastBlock {
    text-align: center;
}
.fooText {
    max-width: 50%;
    margin: 2rem auto 4rem;
    font-weight: 300;
    line-height: 1.95;
}
.footer {
    background-color: #fff;
    padding: 40px 15px;
    position: relative;
    bottom: 0; 
}
.cont-footer {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.lfBottom {
    margin-top: 6rem;
}
.landingFooterBottom {
    width: auto;
    /* position: absolute; */
    bottom: 0;
}
.cRight {
    max-width: 39%;
}
.footerMenu {
    max-width: 60%;
    text-align: end;
}
.footerMenu a {
    color:#151515;
    text-decoration: none;
    margin-right: 10px;
}
.footerMenu a:hover {
    color:#c0c0c0;
    transition: .8s;
}
@media (max-width:768px) {
    .footer {
        position: relative;
    }
}
@media screen and (max-width: 768px) {
    .blockTitle {
        font-size: 45px;
        font-size: var(--title-fszm);
    }
}
/* Settings page */
.bodyWrapper.settings .footer {
    position: relative;
}
.settings__title {
    text-align: center;
    position: absolute;
    margin-bottom: 0; 
    color: #000000;
    font-weight: bold;
}
.h2 {
    border: none;
    font-size: 3.5rem;
}
.settings__title-wrapper {
      position: relative;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      text-align: center;
      text-transform: uppercase;
      color: transparent;
      opacity: 0.5;
      -webkit-text-stroke: 1px #FFAD01;
      font-weight: bold;
      font-size: 100px;
      line-height: 1.22;
      margin-bottom: 55px;
      white-space: nowrap; 
}
.section-one__left {
    /* display: flex; */
}
.section-one__left a {
    text-decoration: none;
}
.section-one__left a:hover {
    color: #c4c4c4;
}
.phone-number input, .mail-number input {
    max-width: 448px;
}
.phone-number__title, .mail-number__title, .balance__title, .accounts__title {
    font-weight: bold;
    font-size: 28px;
    line-height: 120%;
    text-transform: uppercase;
    color: #252525;
    margin-bottom: 14px;
}
.phone-number__under {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    color: #676767;
    margin-top: 10px;
}
.mail-number, .balance, .accounts {
    margin-top: 3rem;
}
.balance .accordion-item {
    border: 0!important;
}
.accordion-button:not(.collapsed) {
    color: #000000;
    background-color: transparent;
    box-shadow: none;
}
.ava-box__title {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    text-transform: uppercase;
    color: #8F8F8F;
}
.ava-box__image {
    display: -webkit-flex;
    display: flex;
    padding: 1.5rem 0;
}
.ava-box__image img {
    margin: 0 auto;
}
.accounts__text {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    color: #676767;
}



.accounts__buttons, .update-and-cancel {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 1.5rem;
}
.green-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
    padding: 12px 16px;

    /* position: absolute;
    width: 156px;
    height: 54px;
    left: 469px;
    top: 1535px; */
    color: #ffffff;
    background: linear-gradient(55.34deg, #89C865 3.28%, #B4F091 92.44%);
    border-radius: 8px;
}

.ml10 {
    margin-left: 10px;
}
.delimeter {
    width: 66px;
    height: 0px;
    border: 1px solid #D9D9D9;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}
.blue-gradient-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 12px 16px;

    /* position: absolute;
    width: 248px;
    height: 54px;
    left: 697px;
    top: 1535px; */
    color: #ffffff;
    background: linear-gradient(97.79deg, #4E9FE2 0%, #7C6DE2 98.12%);
    border-radius: 8px;
}
@media (max-width: 768px) {
        .settings__title-wrapper {
          font-size: 65px;
          line-height: 1.2;
          margin-bottom: 37px; } 
        .section-one {
            -webkit-flex-direction: column-reverse;
                    flex-direction: column-reverse;
        }
}
@media (max-width: 575px) {
        .settings__title-wrapper {
          white-space: normal;
          font-size: 45px;
          line-height: 55px;
          -webkit-align-items: flex-start;
                  align-items: flex-start; 
        } 
}
@media (max-width: 360px) {
        .settings__title-wrapper {
          margin-bottom: -57px; }
}

/* dog to nft condert block */
.dog-to-nft {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    padding: 2rem 0;
    -webkit-align-items: center;
            align-items: center;
    
}
.dog-to-nft__center {
    width: 375px;
    height: 400px;
    margin: 0 -265px;
    background: url(/static/media/nft-rect.5f19124d.svg) no-repeat;
    background-size: cover;
    background-position: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.dog-to-nft__price {
    font-size: 28px;
    line-height: 34px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;

    color: #3E3E3E;
}
.dog-to-nft__price-bottom {
    font-weight: bold;
}

@media (max-width: 768px) {
    .dog-to-nft {
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    .dog-to-nft__center {
        width: 300px;
    }
    .dog-select__body-dog img, .dog-select__body-nft img {
        max-width: 130px;
    }
}

.aboutFirst p {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.cBodyLeft p {
    margin: 0
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.card-title {
    color: #ffffff;
    font-weight: 600;
}
.card-body p {
    color: #d0d0d0;
    font-weight: 300;
    font-size: 16px;
}
.priceBottom {
    display: -webkit-flex;
    display: flex;
}
.regPrice {
    color: #FFAD01;
    font-size: 18px;
    font-weight: 600;
}
.ready2nft img {
    width: 25px;
    height: 30px;
    left: 232px;
    top: 11px;
    /* background: #FFFFFF; */
    /* box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25); */
    -webkit-transform:rotate(-45deg);
  transform: rotate(-45deg);
}
.card_img{
    width:260px;
}
.gradientButton {
    width: 219px;
    /* background: linear-gradient(97.32deg, #7981EB 9.51%, #F16085 91.96%), #FFAD01; */
    background: var(--linear-button-bg);
    border-radius: 8px;
    color: #ffffff;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    padding: 16px 32px;
    text-decoration: none;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}
.gradientButton:hover {
    color: #dfdfdf;
}
.todayMarket .gradientButton {
    margin: 2.6rem auto;
}
.todayMarket .gradientButton:hover {
    color: #f1f1f1;
}
.orangeButton {
    background: #FFAD01;
    border-radius: 8px;
    color: #ffffff;
    text-decoration: none;
    padding: 16px 32px;

}
.orangeButton:hover {
    color: #dfdfdf;
}
.features {
    margin-top: 115px;
    background: radial-gradient(50% 50% at 50% 50%, #3A2441 0%, rgba(58, 36, 65, 0) 100%);
    /* background: url('../img/star6.png') no-repeat; */
}
.featRow {
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    padding: 10rem 0;
}
.featRow .card {
    width: 227px;
    height: 100%;
    background: transparent;
    border: none;
}
.featRow .card img {
    width: auto;
    height: 200px;
    margin: -80px auto;
    z-index: 1;
}
.featRow .card-body {
    font-style: normal;
    height: 327px;
    font-weight: 300;
    font-size: 18px;
    line-height: 120%;
    text-align: center;
    color: #C1C1C1;
    padding-top: 90px;
    border: 1px solid rgba(250, 250, 250, 0.25);
    border-radius: 34px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    line-height: 24px;
}

@media (max-width:768px) {
    .featRow {
        padding: 8rem 0 6rem 0;
    }
    .features .blockTitleOverlay {
        font-size: 37px;
        line-height: 80px;
        margin-bottom: -8rem;
    }
    .features .blockTitle {
        font-size: 36px;
        line-height: 44px;
        margin-bottom: 0;
    }
    .featRow .card {
        width: 100%;
    }
}
.heroSection {
    height: 800px;
    /* background: radial-gradient(129.61% 88.13% at 49.27% 2.67%, #3D2644 0%, #000000 100%); */
    background: url(/static/media/Star1.a41f480f.png), radial-gradient(129.61% 88.13% at 49.27% 2.67%, #3D2644 0%, #000000 100%);
    background-repeat: no-repeat;
    background-position: top;
}
.heroSection h1 {
    font-size: 3em;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    padding: 3rem 0;
    font-weight: 600;
}
.cloudsHero {
    background: url(/static/media/clouds.d02d6c72.svg) no-repeat;
    background-size: auto;
    background-position: center;
}

.textOrange { color: var(--color-orange);}
.contentWrapper .row {
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.heroDogCenter {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.heroLeftBlock, .heroRightBlock {
    width: 300px;
    height: 420px;
    /* left: calc(50% - 300px/2 - 442px);
    top: 297px; */
    padding: 16px;
    background: linear-gradient(163.37deg, rgba(24, 25, 28, 0.25) -1.49%, rgba(24, 25, 28, 0) 100.05%);
    -webkit-backdrop-filter: blur(16px);
            backdrop-filter: blur(16px);
    /* Note: backdrop-filter has minimal browser support */
    border: 1px solid #ffffff;
    border-radius: 32px;
    margin: 42px auto;
}

.heroLeftBlock .card-img-bg, .heroRightBlock .card-img-bg {
    background: linear-gradient(122.4deg, #7981EB -1.47%, #F16085 102.19%);
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);
    border-radius: 32px;
    width: 268px;
    height: 268px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.card-img-top {width: 241.2px;}

.heroButtons {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 4rem;
}
@media screen and (max-width: 768px) {
    .heroSection {
        height: auto;
    }
    .hero-buy {display: none;}
    .cloudsHero {
        background-size: cover;
        -webkit-align-items: baseline;
                align-items: baseline;
    }
    .heroLeftBlock, .heroRightBlock {
        width: 219px;
        height: 321px;
    }
    .heroLeftBlock .card-img-bg, .heroRightBlock .card-img-bg {
        width: 187px;
        width: 187px;
    }
    .heroDogCenter img {
        width: 202px;
    }
    .card-img-top {
        width: 90.8px;
    }
    .heroButtons {
        margin-top: 1.5rem;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    .olButton {
        width: 160px;
        padding: 15px 25px;
        margin: 0;
    }
    .gradientButton {
        width: 160px;
        font-size: 14px;
        line-height: 20px;
        padding: 10px 12px;
    }
}
.foo {
  color: #ffffff;
  max-height: 734px;
  background: linear-gradient(
    97.32deg,
    rgba(121, 129, 235, 0.3) 9.51%,
    rgba(241, 96, 133, 0.3) 91.96%
  );
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(16px);
          backdrop-filter: blur(16px);
  border-radius: 32px 32px 0px 0px;
  padding: 50px;
}

.fooLastBlock {
  text-align: center;
}
.fooText {
  max-width: 50%;
  margin: 2rem auto 4rem;
  font-weight: 300;
  line-height: 1.95;
}
@media (max-width: 1420px) {
  .foo {
    padding: 5px;
  }
  .foo .blockTitle {
    font-size: 59px;
    line-height: 45px;
  }
  .fooText {
    max-width: 100%;
  }
  .landingFooterBottom {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
  }
  .cRight,
  .footerMenu {
    width: 100%;
    text-align: center;
    font-size: 13px;
  }
}
@media (max-width: 1200px) {
  .foo {
    padding: 5px;
  }
  .foo .blockTitle {
    font-size: 40px;
    line-height: 45px;
  }
  .fooText {
    max-width: 100%;
  }
  .landingFooterBottom {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .cRight,
  .footerMenu {
    width: 100%;
    text-align: center;
    font-size: 13px;
  }
}


.learnBlock {
    width: 100%;
    height: 459px;
    background: linear-gradient(97.32deg, #7981EB 9.51%, #F16085 91.96%);
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);
    border-radius: 32px;
    display: -webkit-inline-flex;
    display: inline-flex;
    margin: 3rem auto;
}
.learnBlock img {
    max-width: 100%;
}
.lbLeft {
    width: 60%;
    padding: 57px 50px;
}
.lbRight {
    width: 39%;
    padding: 22px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.lbTitle {
    font-weight: bold;
    font-size: 60px;
    line-height: 55px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 57px;
}
.lbContent {
    font-weight: 300;
    font-size: 18px;
    line-height: 1.8;
    color: #D9D9D9;
    margin-bottom: 2rem;
}
.learn-images {
    max-width: 100%;
}

@media (max-width:1420px) {
    .learnBlock {
        -webkit-flex-direction:column-reverse!important;
                flex-direction:column-reverse!important;
        height: auto;
    }
    .lbLeft, .lbRight {
        width: 100%;
    }
    .lbLeft object, .lbRight object {
        max-width: 430px;
    }
    .lbTitle {
        font-size: 45px;
        line-height: 55px;
    }
    .lbTwo {
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .learn-images {
        max-width: 450px;
    }
    .learnBlock .orangeButton {
        padding: 12px 24px;
    }
}
@media (max-width:1200px) {
    .learnBlock {
        -webkit-flex-direction:column-reverse!important;
                flex-direction:column-reverse!important;
        height: auto;
    }
    .lbLeft, .lbRight {
        width: 100%;
    }
    .lbLeft object, .lbRight object {
        max-width: 430px;
    }
    .lbTitle {
        font-size: 34px;
        line-height: 45px;
    }
    .lbTwo {
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .learn-images {
        max-width: 400px;
    }
    .learnBlock .orangeButton {
        padding: 12px 24px;
    }
}
@media (max-width:768px) {
    .learnBlock {
        -webkit-flex-direction:column-reverse!important;
                flex-direction:column-reverse!important;
        height: auto;
    }
    .lbLeft, .lbRight {
        width: 100%;
    }
    .lbLeft object, .lbRight object {
        max-width: 330px;
    }
    .lbTitle {
        font-size: 28px;
        line-height: 40px;
    }
    .lbTwo {
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .learn-images {
        max-width: 340px;
    }
    .learnBlock .orangeButton {
        padding: 12px 24px;
    }
}
.todayMarket .row {
    -webkit-justify-content: center;
            justify-content: center;
}
.todayMarket .card {
    width: 274px;
    height: 348px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid #C5C5C5;
    box-sizing: border-box;
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
    border-radius: 16px;
    margin: 0 auto;
    overflow: hidden;
}
.todayMarket .card-img-bg {
    background: linear-gradient(55.34deg, #6058D5 3.28%, #816BA4 92.44%);
    height: 255px;
}
.todayMarket .col-md {
    margin: 15px;
    max-width: 274px;
}
.todayMarket .card-body {
    padding: 5px 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}
.todayMarket .card-body p {
    margin: -10px 0 5px 0;
}
@media (max-width:768px) {
    .todayMarket .row {
        display: grid;
        grid-gap: 25px;
    }
    .todayMarket .col-md {
        margin: 0;
        max-width: 100%;
    }
}
.pagination .page-link {
    color: #212121;
    text-decoration: none;
    border: 0;
}
.convert-page__top-line {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 3rem 0;
}
.step {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}
.step__number {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    color: #ffffff;
    font-size: 1.6em;
    margin-right: 15px;
    background: #C4C4C4;
}

.step__info {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: #c4c4c4;
    margin-right: 15px;
}
.step__line {
    width: 97px;
    height: 0px;
    border: 1px solid #C4C4C4;
    margin-right: 15px;
}

.active_step{
 background: linear-gradient(
        122.4deg
        , #7981EB -1.47%, #F16085 102.19%), #C4C4C4;;;
        
}
.filled-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    padding: 16px 32px;
    color: #ffffff;
    font-size: 16px;
    text-decoration: none;
    width: 203px;
    height: 54px;
    background: linear-gradient(
97.32deg
, #7981EB 9.51%, #F16085 91.96%);
    opacity: 0.5;
    border-radius: 8px;
    margin-right: 1rem;
}

.button_active{
    background: linear-gradient(
97.32deg
, #7981EB 9.51%, #F16085 91.96%);
opacity: 1.0;
}

.filled-button-cancel{
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    max-width: 144px;
    width: 100%;
    height: 54px;
    border-radius: 8px;
    background: linear-gradient( 
90deg
 , #7981eb 0%, #f16085 100%);
    text-decoration: none;
    transition: 0.3s;
    margin-top: 1px;
}

.outline_cancel{
    color: #636363;
    background-color: #ffffff;
    padding: 16px 32px;
    text-align: center;
    border-radius: 8px;
    text-decoration: none;
    display: -webkit-flex;
    display: flex;
    max-width: 140px;
    height: 50px;
    width: 100%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}


.bg-white {
    /*height: calc(100vh - 56px);*/
    height: 100%;
}
.style_text_select{
    font-size: 19px;
    line-height: 22px;
    font-weight: bold;
}

.style_text_bottom{
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    color: #636363;
    font-weight: bold;
    padding: 3rem 0;
    max-width: 600px;
    margin: 0 auto;
}

.button-bottom-ntf {
    padding-bottom: 6rem;
}

.block{
    width: 253px;
    height: 298px;
    background: #F3F3F3;
    border: 2px dashed #9E9E9E;
    border-radius: 16px;
    overflow: hidden;
   display: -webkit-flex;
   display: flex;
   -webkit-justify-content: center;
           justify-content: center;
   -webkit-align-items: center;
           align-items: center;
}

/* .block img {
    width: 253px;
    height: 298px;
} */

.block:hover{
   background-color: rgb(82, 82, 82);
   border: 2px dashed snow;
    transition: 1s;
}
.transition3:not(:hover) {
    background: #F3F3F3;
    border: 2px dashed #9E9E9E;
    transition: 3s;
}

@media (max-width:768px) {
    .bg-white {
        height: 100%;
    }
    .step__number {
       
        width: 30px;
        height: 30px;
        font-size: 1.2em;
        margin-right: 5px;
    }
    .step__info {
        font-size: 11px;
        line-height: 14px;
        margin-right: 0;
        text-align: center;
    }
    .step__line {
        width: 22px;
        height: 0px;
        margin-right: 10px;
    }
}
.active_step_done{
    background: linear-gradient(
        122.4deg
        , #7981EB -1.47%, #F16085 102.19%), #C4C4C4;;
}
.convert_center_block{
    width: 375px;
    height: 400px; 
    background-image: url('https://temp.coindogs.com/l/img/nft-rect.svg') ;
    background-size: cover;
    background-position: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.total{
    font-size: 28px;
    line-height: 34px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    color: #3E3E3E;
}

.price{
    font-weight: bold;
}
.image_block{
    width: 253px;
    height: 298px;
    background: linear-gradient(
        97.32deg
        , #7981EB 9.51%, #F16085 91.96%);
    border-radius: 16px;
    overflow: hidden;
}

.image_block img{
    width: 253px;
    height: 298px;
}
