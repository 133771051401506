.convert-page__top-line {
    display: flex;
    justify-content: center;
    padding: 3rem 0;
}
.step {
    display: flex;
    align-items: center;
}
.step__number {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    color: #ffffff;
    font-size: 1.6em;
    margin-right: 15px;
    background: #C4C4C4;
}

.step__info {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #c4c4c4;
    margin-right: 15px;
}
.step__line {
    width: 97px;
    height: 0px;
    border: 1px solid #C4C4C4;
    margin-right: 15px;
}

.active_step{
 background: linear-gradient(
        122.4deg
        , #7981EB -1.47%, #F16085 102.19%), #C4C4C4;;;
        
}