.gradientButton {
    width: 219px;
    /* background: linear-gradient(97.32deg, #7981EB 9.51%, #F16085 91.96%), #FFAD01; */
    background: var(--linear-button-bg);
    border-radius: 8px;
    color: #ffffff;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 32px;
    text-decoration: none;
    justify-content: space-around;
}
.gradientButton:hover {
    color: #dfdfdf;
}
.todayMarket .gradientButton {
    margin: 2.6rem auto;
}
.todayMarket .gradientButton:hover {
    color: #f1f1f1;
}
.orangeButton {
    background: #FFAD01;
    border-radius: 8px;
    color: #ffffff;
    text-decoration: none;
    padding: 16px 32px;

}
.orangeButton:hover {
    color: #dfdfdf;
}