.features {
    margin-top: 115px;
    background: radial-gradient(50% 50% at 50% 50%, #3A2441 0%, rgba(58, 36, 65, 0) 100%);
    /* background: url('../img/star6.png') no-repeat; */
}
.featRow {
    justify-content: space-evenly;
    padding: 10rem 0;
}
.featRow .card {
    width: 227px;
    height: 100%;
    background: transparent;
    border: none;
}
.featRow .card img {
    width: auto;
    height: 200px;
    margin: -80px auto;
    z-index: 1;
}
.featRow .card-body {
    font-style: normal;
    height: 327px;
    font-weight: 300;
    font-size: 18px;
    line-height: 120%;
    text-align: center;
    color: #C1C1C1;
    padding-top: 90px;
    border: 1px solid rgba(250, 250, 250, 0.25);
    border-radius: 34px;
    display: flex;
    flex-direction: column;
    line-height: 24px;
}

@media (max-width:768px) {
    .featRow {
        padding: 8rem 0 6rem 0;
    }
    .features .blockTitleOverlay {
        font-size: 37px;
        line-height: 80px;
        margin-bottom: -8rem;
    }
    .features .blockTitle {
        font-size: 36px;
        line-height: 44px;
        margin-bottom: 0;
    }
    .featRow .card {
        width: 100%;
    }
}