.learnBlock {
    width: 100%;
    height: 459px;
    background: linear-gradient(97.32deg, #7981EB 9.51%, #F16085 91.96%);
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);
    border-radius: 32px;
    display: inline-flex;
    margin: 3rem auto;
}
.learnBlock img {
    max-width: 100%;
}
.lbLeft {
    width: 60%;
    padding: 57px 50px;
}
.lbRight {
    width: 39%;
    padding: 22px;
    display: flex;
    justify-content: center;
}

.lbTitle {
    font-weight: bold;
    font-size: 60px;
    line-height: 55px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 57px;
}
.lbContent {
    font-weight: 300;
    font-size: 18px;
    line-height: 1.8;
    color: #D9D9D9;
    margin-bottom: 2rem;
}
.learn-images {
    max-width: 100%;
}

@media (max-width:1420px) {
    .learnBlock {
        flex-direction:column-reverse!important;
        height: auto;
    }
    .lbLeft, .lbRight {
        width: 100%;
    }
    .lbLeft object, .lbRight object {
        max-width: 430px;
    }
    .lbTitle {
        font-size: 45px;
        line-height: 55px;
    }
    .lbTwo {
        flex-direction: column;
    }
    .learn-images {
        max-width: 450px;
    }
    .learnBlock .orangeButton {
        padding: 12px 24px;
    }
}
@media (max-width:1200px) {
    .learnBlock {
        flex-direction:column-reverse!important;
        height: auto;
    }
    .lbLeft, .lbRight {
        width: 100%;
    }
    .lbLeft object, .lbRight object {
        max-width: 430px;
    }
    .lbTitle {
        font-size: 34px;
        line-height: 45px;
    }
    .lbTwo {
        flex-direction: column;
    }
    .learn-images {
        max-width: 400px;
    }
    .learnBlock .orangeButton {
        padding: 12px 24px;
    }
}
@media (max-width:768px) {
    .learnBlock {
        flex-direction:column-reverse!important;
        height: auto;
    }
    .lbLeft, .lbRight {
        width: 100%;
    }
    .lbLeft object, .lbRight object {
        max-width: 330px;
    }
    .lbTitle {
        font-size: 28px;
        line-height: 40px;
    }
    .lbTwo {
        flex-direction: column;
    }
    .learn-images {
        max-width: 340px;
    }
    .learnBlock .orangeButton {
        padding: 12px 24px;
    }
}